import { graphql, useStaticQuery } from 'gatsby';

import { CategoryImageStyled } from '../../styles/pages/search/category';
import { Title } from '../../styles/pages/search';

import { Company } from '../../components/forms/search-services/company';
import { navigateToError } from '../../utils/navigate';
import React, { useContext, useEffect } from 'react';
import Topbar from '../../components/responsive/topbar';
import Layout from '../../components/responsive/layout';
import { LangContext } from '../../context/lang.context';

const SearchCategoryPage = ({ location }: PageProps) => {
  const windowGlobal: any = typeof window !== 'undefined' && window;
  const { state } = location;
  const { category, token } = state ?? windowGlobal?.history?.state ?? {};
  const { t } = useContext(LangContext);
  useEffect(() => {
    if (!category) navigateToError()?.();
  }, []);

  if (!category) return null;
  const data = useStaticQuery(graphql`
    query {
      allImages: allFile(filter: { extension: { regex: "/(png)/" }, relativeDirectory: { eq: "services" } }) {
        edges {
          node {
            base
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 1000)
            }
          }
        }
      }
      allI18NJson {
        nodes {
          locale
          clientId
          SERVICES {
            ...categoriesText
          }
        }
      }
    }
  `);

  const categoryImage = data.allImages?.edges.find(({ node: { base } }: any) => base === category.image)?.node
    .childImageSharp;

  return (
    <Layout>
      <Topbar />
      <section className="content pb-10">
        <CategoryImageStyled loading="eager" image={categoryImage.gatsbyImageData} alt={'category image'} />
        <Title>{t(data).SERVICES.CATEGORIES[category.name]}</Title>
        <Company token={token} categoryName={category.name} />
      </section>
    </Layout>
  );
};

export default SearchCategoryPage;
